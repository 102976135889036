@import "variables.scss";
@import "mixins.scss";
@import "common.scss";
@import "custom-bootstrap.scss";

$size_x: calc(100vw * 0.652);
$size_y: calc($size_x * 1.47);

.App-wrap {
  height: 100vh;
}

.circle-group {
  background-image: url('../images/Group_725.svg');
  background-repeat: no-repeat;
  background-position: 100% 135%;
}

.not-found-page {
  height: inherit;
  background-image: url('../images/Group_725.svg');
  background-repeat: no-repeat;
  background-position: top 15% right -45%;
  background-size: auto;
  background-color: $bg_1;
  h1 {
    margin-top: 150px;
  }
  a.nav-link {
    color: $color_2;
    &:hover {
      color: lighten($color_2, 10%);
    }
  }
  .logo {
    padding: 20px 40px;
  }
}

.contacts-page {
  background: url('../images/ellipse_3_a.svg') right -#{calc(100vw * 0.063)} bottom -#{calc(0.439 * $size_y)} / #{$size_x} $size_y  no-repeat, $bg_1;
  @include to-lg {
    background: url('../images/ellipse_3_a.svg') left -220px bottom -400px / 883px 883px  no-repeat, $bg_1;
  }
}

.contacts-page:has(.section-empty)  {
  background: $bg-1;
}
