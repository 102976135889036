@keyframes pulse-ring {
  0% {
    transform: scale(0.2);
  }
  
  80%,
  100% {
    opacity: 0;
  }
}

.pulsator {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 150%;
    height: 150%;
    box-sizing: border-box;
    margin-left: -25%;
    margin-top: -25%;
    border-radius: 100%;
    background: linear-gradient(90deg, #007dc3 0%, #35b0cc 46%, #77f0d8 99%, #77f0d8 100%);
    animation: pulse-ring 1.75s cubic-bezier(0.215, 0.61, 0.355, 0.5) infinite;
    z-index: 1;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 125%;
    height: 125%;
    margin-left: -12.5%;
    margin-top: -12.5%;
    background: linear-gradient(125deg, #007dc3 0%, #35b0cc 46%, #77f0d8 99%, #77f0d8 100%);
    border-radius: 100%;
    animation: pulse-ring 1.75s cubic-bezier(0.215, 0.61, 0.355, 0.1) infinite;
    z-index: 1;
  }
}