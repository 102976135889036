@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

// 2. Include any default variable overrides here
$link-color: $color_2;
$link-decoration: none;
$link-hover-color: lighten($color: $color_2, $amount: 15%);

$font-size-root: 18px;
$font-family-base: join('Gilroy', $font-family-sans-serif);

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

// 4. Include any optional Bootstrap CSS as needed
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/helpers";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/offcanvas";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/accordion";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/transitions";

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../../node_modules/bootstrap/scss/utilities/api";

@import "./fonts/style.css";


body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  color: $color_3;
}

h1 {
  font-family: 'Gilroy-Medium';
  @include typography($size: 64px, $height: 79px, $weight: 700);
  @include rewriteMargin($bottom: 30px);
  @include to-lg {
    @include typography($size: 42px, $height: 52px);
  }
}

h2, h1.h2 {
  font-family: 'Gilroy-Medium';
  @include typography($size: 42px, $height: 51px, $weight: 700);
  @include rewriteMargin($bottom: 50px);
  @include to-lg {
    @include typography($size: 32px, $height: 40px);
  }
}

h4 {
  font-family: 'Gilroy-Medium';
  @include typography($size: 24px, $height: 31px, $weight: 500);
  @include rewriteMargin($bottom: 20px);
  @include to-lg {
    @include typography($size: 22px);
  }
}

p {
  @include typography($size: 18px, $height: 26px);
  color: $color_4;
  @include to-lg {
    @include typography($size: 16px);
  }
}

ul, ol {
  padding-left: 0;
}

a:not(li.breadcrumb-item a, .filter a, .navbar-nav a) {
  font-size: 16px;
}
