@use "sass:math";

:root {
  --main-page-second-svg-offset-top: 1000px;
}

$color_1: #FFFFFF;
$color_2: #007DC3;
$color_3: #151515;
$color_4: #5B5B5B;
$color_5: #0497EA;
$color_6: #222222;
$color_7: #9D9D9D;
$color_8: #2F2F2F;
$color_9: #6F6F6F;
$bg_1: #F8F9FA;
$bg_2: #35353C;
$bg_3: #F8F8F8;
$bg_4: #31313F;
$bg_5: #E0E0E0;

$header_height_full: 1139px;
$header_height_mobile: 853px;

$tg: math.div(130, 1920);
$clip: $tg * 100vw;

$padding_x: 15px;
$about_us_basic_padding: 30px;

$section_padding_top_xl: 150px;
$section_padding_top_md: 100px;
$section_h2_padding_bottom_xl: 50px;
$section_h2_padding_bottom_md: 50px;
$h2_fontsize_xl: 42px;
$h2_fontsize_md: 32px; 
 
//feature carousel sizes
$basic_image_width: 300px;
$basic_xl_image_width: 270px;

// main page slider sizes
$main_page_slider_width: calc(100vw / 2 - $padding_x * 2);
$main_page_slider_height: calc(0.525 * $main_page_slider_width);
$main_page_slider_width_md: 100vw;
$main_page_slider_height_sm: 301px;
$main_page_slider_height_md: 360px;

// breadcrumb settings
$breadcrumb_font_size: 18px;
$breadcrumb_margin_top: 50px;
$breadcrumb_item_padding_x: 25px;
