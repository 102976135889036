.link {
  color: $color_2;
  &:hover {
    color: lighten($color: $color_2, $amount: 15%);
  }
}

.link-as-button {
  display: inline-block;
  background-color: $color_2;
  color: $color_1;
  border-radius: 4px;
  border: none;
  padding: 18px 94px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-top: 14px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: $color_1;
  } 
  @include to-lg {
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
}

.logotype {
  font-weight: bold;
  font-size: 18px;
  line-height: 194.3%;
  letter-spacing: 0.05em;
}

.img-cover {
  @include inherited-sizes;
  object-fit: cover;
  image-rendering: -webkit-optimize-contrast;
}

.inherited-sizes {
  @include inherited-sizes;
}

.rounded-4 {
  border-radius: 4px !important;
}

.border-radius-bottom-4 {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.cursor-default {
  cursor: default !important;
}

.section {
  padding-bottom: $section_padding_top_xl;
  @include to-lg {
    padding-bottom: $section_padding_top_md;
  }
}

.section:first-child {
  padding-top: 100px;
  > h2, h1.h2 {
    margin-bottom: 30px !important;
  }
  @include to-lg {
    padding-top: 70px;
  }
}

.section:last-child {
  padding-bottom: calc(2 * $clip + $section_padding_top_xl);
  @include to-lg {
    padding-bottom: calc(2 * $clip + $section_padding_top_md);
  }
}

.flex-row-center-center {
  @include flex($align: center);
}

.presentation-carousel-dot{
  border: 1px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $color_2;
  opacity: 1;
  margin: 0 3px;
  padding: 0;
  transition: background-color 0.5s ease-in-out, opacity 0.5s ease-in-out, width 0.5s ease-in-out, border-radius 0.5s ease-in-out;
  font-size: 0;
  &:focus {
    box-shadow: none;
  }
  &.active {
    border: 1px;
    width: 32px;
    background-color: $color_2;
    border-radius: 22.56px;
    cursor: default;
  }
  &.active ~ *{
    background-color: $bg_5;
    opacity: 0.6;
  }
}

.btn-arrow {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  &:focus {
    box-shadow: none;
  }
  svg.svg-arrow {
    fill: black;
    opacity: 0.4;
    &:hover{
      opacity: 1;
    }
  }
}

.card-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  gap: 30px;
  .card {
    border-radius: 4px;
    border-color: transparent;
    background-color: $color_1;
    padding: 40px;
    h4 {
      margin-bottom: 20px;
      color: $color_3;
      min-height: 57px;
    }
    span, p ,ul {
      margin: 0;
    }
    li {
      color: $color_4;
    }
  }
  @media screen and(min-width: 768px) and(max-width: 1199px){
    grid-template-columns: repeat(2, 1fr);
  }
  @include to-md{
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    .card {
      display: block;
      h4 {
        margin-bottom: 20px;
      }
    }
  }
}

.filter-wrapper {
  flex-shrink: 1;
  width: auto;
  overflow-x: auto;
  margin: auto 0;
  position: relative;
  scrollbar-color: rgba($color: $color_4, $alpha: 0.2) transparent;
  scrollbar-width: 8px !important;
}

.filter-outer {
  position: relative;
  flex-shrink: 1;
  width: auto;
  overflow: hidden;
  @include flex($align: center);
  @include to-lg {
    width: 100%;
    flex-shrink: 0;
    justify-content: start;
  }
}

.filter-outer .btn-arrow {
  background-color: $bg_1;
  height: 100%;
  margin-bottom: 8px;
  svg {
    width: 30px;
  }
}

.filter-outer .btn-filter-prev {
  padding-right: 10px;
}

.filter-outer .btn-filter-next {
  padding-left: 10px;
}

.filter-wrapper::-webkit-scrollbar {
  background-color: transparent;
  height: 8px;
  width: 8px;
}

.filter-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba($color: $color_4, $alpha: 0.2);
  border-radius: 8px;
}

.filter {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  a {
    text-decoration: none;
    font-size: 24px;
    color: $color_9;
    padding-right: 55px;
    font-weight: 500;
    white-space: nowrap;
    &.active {
      color: $color_2;
    }
    &:last-child {
      padding-right: 0;
    }
    &:hover {
      color: $color_2;
    }
  }
}


.button-wrapper {
  background: transparent;
  @include flex($align: center);
  padding-top: 50px;
  button {
    background-color: transparent !important;
    border-radius: 4px;
    color: $color_2;
    border: 1px solid $color_2;
    font-size: 16px;
    padding: 12px 45px;
    &:hover:not(:disabled){
      color: $color_1;
      background-color: $color_2 !important;
    }
    &.isLoading {
      padding: 13px 45px;
      border: none;
    }
  }
  @include to-lg {
    button {
      width: 100%;
    }
  }
  span {
    vertical-align: middle;
  }
}

.theme-button {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 18px 32px;
  background-color: $color_2;
  color: $color_1;
  border: 0;
  border-radius: 4px;
  @media screen and(max-width: 576px) {
    width: 100%;
  }
}

.mb-50 {
  margin-bottom: 50px;
}

.App {
  background: $color_1;
  .container{
    padding-left: 0;
    padding-right: 0;
    @include to-lg {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.projects-container {
  background: transparent;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.App-wrap {
  height: 100vh;
}

.technology {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 30px;
  @media screen and (max-width: 768px) {
    gap: 18px;
  }
  .item {
    color: $color_2;
    border: 1px solid $color_2;
    padding: 25px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    border-radius: 4px;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      padding: 16px 25px;
    }
  }
}

.flex-container {
  @include flex($align: center);
  @include to-lg {
    flex-direction: column;
  }
}

.projects, .projects-container {
  .nav-link {
    color: $color_3;
    font-size: 18px !important;
    span {
      margin-right: 17px;
    }
    svg {
      fill: $color_3;
    }
    &:hover {
      color: lighten($color_3, 30%);
      svg {
        fill: lighten($color_3, 50%);
      }
    }
  }
  >.container {
    padding-bottom: 50px;
  }
  @include to-lg {
    .nav-link {
      padding-top: 25px !important;
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
