@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
    url('Gilroy-Regular.woff2') format('woff2'),
    url('Gilroy-Regular.woff') format('woff'),
    url('Gilroy-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
    url('Gilroy-Medium.woff2') format('woff2'),
    url('Gilroy-Medium.woff') format('woff'),
    url('Gilroy-Medium.ttf') format('truetype');
 font-weight: 500;
 font-style: normal;
}
