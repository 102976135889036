@mixin inherited-sizes {
  width: inherit;
  height: inherit;
}

@mixin media-xs{
  @media screen and(max-width: 575.98px) {
    @content;
  }
}

@mixin media-sm {
  @media screen and(min-width: 576px) and(max-width: 767.98px){
    @content;
  }
}

@mixin media-md {
  @media screen and(min-width: 768px) and(max-width: 991px) {
    @content;
  }
}

@mixin media-lg {
  @media screen and(min-width: 992px) and(max-width: 1199px) {
    @content;
  }
}

@mixin media-xl {
  @media screen and(min-width: 1200px) and(max-width: 1399px)  {
    @content;
  }
}

@mixin to-md {
  @media screen and(min-width: 0) and(max-width: 767px) {
    @content;
  }
}

@mixin to-lg {
  @media screen and(max-width: 991.98px) {
    @content;
  }
}

@mixin clean-service-item-padding {
  .service-item-content-block, .service-item-image-block {
    padding: 0;
    width: 100%;
  }
  .service-item-image-block {
    margin-bottom: 15px;
  }
}

@mixin rewritePadding($top: 0, $left: 0, $bottom: 0, $right: 0) {
  padding-top: $top !important;
  padding-left:  $left !important;
  padding-bottom:  $bottom !important;
  padding-right:  $right !important;
}

@mixin carousel-indicators {
  .carousel-indicators [data-bs-target] {
    border: 1px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $color_2;
    opacity: 1;
    transition: width 0.6s ease-in, border-radius 0.6s ease-in;
    &.active{
      border: 1px;
      background-color: $color_2;
      width: 32px;
      border-radius: 22.56px;
      opacity: 1;
      transition: background-color 0.6s ease-in-out, opacity 0.6s ease-in-out, width 0.6s ease-in-out, border-radius 0.6s ease-in-out;
    }
    &.active ~ *{
      background-color: $bg_5;
      opacity: 0.6;
    }
  }
}

@mixin transition-list($list) {
  $trans: ();
  @each $val in $list {
    $trans: append($trans, unquote('#{$val} 0.5s ease-in-out'), 'comma');
  }
  transition: $trans;
}

@mixin create-transition($prefix, $val1, $val2, $val3, $val4) {
  .#{$prefix}-enter {
    transform: translateX($val1);
  }
  .#{$prefix}-enter-active {
    transform: translateX($val2);
    @include transition-list('transform');
  }
  .#{$prefix}-exit {
    transform: translateX($val3);
  }
  .#{$prefix}-exit-active {
    transform: translateX($val4);
    @include transition-list('transform');
  }
}

@mixin flex($display: flex, $direction: row, $justify: center, $align: start, $wrap: null) {
  display: $display;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  @if ($wrap) {
    flex-wrap: $wrap;
  }
}

@mixin rewriteMargin($top: 0, $left: 0, $bottom: 0, $right: 0) {
  margin-top: $top !important;
  margin-left:  $left !important;
  margin-bottom:  $bottom !important;
  margin-right:  $right !important;
}

@mixin typography($size: null, $weight: null, $height: null, $family: null, $style: null) {
  @if ($family) {
    font-family: $family;
  }
  @if ($size) {
    font-size: $size;
  }
  @if ($weight) {
    font-weight: $weight;
  }
  @if ($height) {
    line-height: $height;
  }
  @if ($style) {
    font-style: $style;
  }
}

@mixin arrow-out-of-wrapper-correct($prefix) {
  .#{$prefix}-prev {
    @media screen and(min-width:1400px) and(max-width: 1440px) {
      left: -2.5%;
      .btn-arrow svg {
        width: 30px;
      }
    }
    @media screen and(min-width:1200px) and(max-width: 1245px) {
      left: -2%;
      .btn-arrow svg {
        width: 20px;
      }
    }
    @include media-lg {
      left: -5%;
    }
    @media screen and(min-width:992px) and(max-width: 1040px) {
      left: -2.5%;
      .btn-arrow svg {
        width: 20px;
      }
    }
  }

  .#{$prefix}-next {
    @media screen and(min-width:1400px) and(max-width: 1440px) {
      right: -2.5%;
      .btn-arrow svg {
        width: 30px;
      }
    }

    @media screen and(min-width:1200px) and(max-width: 1245px) {
      right: -2%;
      .btn-arrow svg {
        width: 20px;
      }
    }
    
    @include media-lg {
      right: -5%;
    }
    @media screen and(min-width:992px) and(max-width: 1040px) {
      right: -2.5%;
      .btn-arrow svg {
        width: 20px;
      }
    }
  }
}

@mixin blog {
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Gilroy-Medium';
    color: $color_3;
    margin-top: 0;
    margin-bottom: 30px;
    a {
      color: $color_3 !important;
    }
  }
  h1 {
    font-size: 42px;
    line-height: 52px;
    font-weight: 700;
  }
  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
  }
  a {
    text-decoration: none;
  }
  p {
    font-size: 18px !important;
    color: $color_4;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 50px !important;
  }
  ul,ol {
    margin-bottom: 50px;
    padding-left: 2rem;
  }
  ul li, ol li {
    margin-bottom: 10px;
  }
  ul li a[href] {
    font-size: 18px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    color: $color_5;
    border-bottom: 1px solid $color_5;
    &:hover {
      color: lighten($color: $color_5, $amount: 15%);
      border-color: lighten($color: $color_5, $amount: 15%);
    }
  }
  ul li {
    color: $color_5;
    font-size: 18px;
  }
  img + h3, img + p + h3 {
    margin-top: 50px !important;
  }
  img[style*="float: right"] {
    @media screen and(min-width: 992px ) {
      padding-left: 30px;
    }
  }
  img[style*="float: left"] {
    @media screen and(min-width: 992px) {
      padding-right: 30px;
    }
  }
  @include to-md {
    h1,h2,h3 {
      margin: 24px 0;
    }
    h1 {
      font-size: 32px;
      line-height: 39.62px;
    }
    h2,h3 {
      font-size: 22px;
      line-height: 28.6px;
    }
    p {
      width: 100%;
      font-size: 16px;
    }
    ul {
      padding-left: 1rem;
    }
    ul li a[href] {
      font-size: 16px;
    }
    img {
      display: block;
      clear: both;
      width: 100% !important;
      height: auto !important;
      margin: 30px auto !important;
      object-fit: cover;
    }
  }
}